
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../lib/abpbase";
import Campana from "../../store/entities/OZONE/campana";
import SelectCampana from "./campana/selectCampana.vue";
import GenerarExportacion from "../../store/entities/OZONE/generarExportacion";
import DisenoExportacion from  "../../store/entities/OZONE/disenoExportacion";
import { Moment } from "moment";

import vueJsonEditor from "vue-json-editor";

@Component({
  components: {
    SelectCampana,
    vueJsonEditor,
  },
})
export default class DisenosExportacion extends AbpBase {

  editable: DisenoExportacion = null;
  json: any = undefined;
   
  get loading() {
    return this.$store.state.envio.loading;
  }
  get disenos() {
    return this.$store.state.envio.disenos;
  }

  async created() {
    await this.$store.dispatch({
      type: "envio/getDisenos",
      data: { withContent: true },
    });
  }

  loadJSON(id) {
    let diseno = this.disenos.find((d) => d.id == id);
    this.json = JSON.parse(diseno.diseno);
    this.editable = diseno;
  }

  async updateDiseno(value) {
    
    this.editable.diseno = JSON.stringify(value);

    await this.$store.dispatch({
      type: "envio/insertarDiseno",
      data: this.editable,
    });

    this.editable = undefined;
    this.json = undefined;
  }

  columns = [
    {
      title: "Diseño",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Tipo de diseño",
      dataIndex: "disenoTipo",
      key: "disenoTipo",
      scopedSlots: { customRender: "disenoTipo"}
    },
    {
      title: "Cliente",
      dataIndex: "cliente.nombre",
      key: "cliente.nombre"     
    },
    {
      title: "Action",
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];
}
